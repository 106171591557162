<template>
  <article class="404 page-404">
    <div class="container page-404__container">
      <img
        src="img/404.jpg"
        alt=""
      >
      <div class="page-404__title">Ohh! Página no encontrada</div>
      <div class="page-404__subtitle">No pudimos encontrar la página que estas buscando</div>
      <router-link
        to="/"
        class="btn page-404__btn"
      >Regresar a inicio</router-link>
    </div>
  </article>
</template>
<script>
export default {
  name: "404",
};
</script>